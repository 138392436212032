/********************************* DOMAINS FORM SUBMIT *************************************/

// Split domain search and use TLD and SLD

// Homepage form
$('.domain-search-form form').submit(function(e){
    submitDomain(e);
});

// Domains page form
$('#domain-search-form form').submit(function(e){
    submitDomain(e);
});

function submitDomain(e){
    domain = $('#initdomain').val();
    console.log(e);

    if(domain == ''){
        e.preventDefault();
        alert('Please enter a domain name');
    } else {
        if(domain.indexOf('.') >= 0){
            host = domain.split(/\.(.+)?/)[0];
            tld = '.' + domain.split(/\.(.+)?/)[1];
            $('#domain').val(host);
            $('#tlds').val(tld);
        }else{
            $('#domain').val(domain);
        }
        return;
    }
}


/************* FROM HERE ONWARDS IS ONLY FOR DOMAINS PAGE *************/

/*
    TODO:
        Add search histore
        Click a TLD to search on it eg. as per new Netregistry
*/

// Sub Navigation (in page navigation)
$(window).scroll(function(){
	//if($(window).width() > 850){
		if($(window).scrollTop() > ($('.inner-banner').height() + 100)){
			$('#domain-search-form').addClass('sticky-menu');
			$('#domain-search-form h2').css('display', 'none');
			$('#domain-search-form #transfer').css('display', 'none');
			$('#form-area').css('display', 'block');
			formAreaHeight();
		}else{
			$('#domain-search-form').removeClass('sticky-menu');
			$('#domain-search-form h2').css('display', 'block');
			$('#domain-search-form #transfer').css('display', 'block');
			$('#form-area').css('display', 'none');
			formAreaHeight();
		}
	//}
});

$(window).resize(function(){
	formAreaHeight();
});

function formAreaHeight(){
	$("#form-area").height( $("#domain-search-form").outerHeight(true) );
}



//https://business.uber.com.au/index.php?NAME_PATH=DOMAINS_PATH&transfer=0&domain=blah&tld=com.au
var storeUrl = "https://business.uber.com.au/index.php?NAME_PATH=DOMAINS_PATH&transfer=0";


// TLD groups and sort orders.
// allPremium group will contain the TLDs from premiumGroup and all TLDs which don't have a group.

var tldGroups = {

	"nz" : {
		cssId : "results_nz",
		tlds : ['nz','kiwi',/* 'co.nz', */'kiwi.nz','net.nz','org.nz','gen.nz','geek.nz','ac.nz','maori.nz','school.nz']
	},
    
    "generic" : {
		cssId : "results_generic", 
		tlds : ['com','net','org','info','biz','asia','mobi','travel']
	},
	
	"international" : {
		cssId : "results_international", 
		tlds : ['co','com.au','net.au','co.uk','org.uk','us', 'au', 'id.au', 'org.au']
	},
	
	"premium" : {
		cssId : "results_premium",
		tlds : ['guru','photography','today','tips','technology','bike','clothing','directory','gallery','land']
	},
	
	"allPremium" : {
		cssId : "results_premium", 
		tlds : [] // leave tlds blank, they will be added automatically.
	} 

};


//var tldObj is set by domains_1.js

var domainObj;


$(document).ready(function(){
	console.log("domain init");
    
    initSearchHistory();
	
	if(typeof tldObj == 'undefined'){
		logMessage('There was a problem contacting the domain server. <a href="https://business.uber.com.au/index.php?NAME_PATH=DOMAINS_PATH">Please search for domains using our store.</a>');
	}else{
		createAllPremiumGroup();
		
		console.log("tldObj:", tldObj);
		console.log("tldGroups:", tldGroups);
		
		initDomainPricing(tldGroups.nz);
        initDomainPricing(tldGroups.generic);
		initDomainPricing(tldGroups.international);
		initDomainPricing(tldGroups.premium);
	}
});


// Create a group of all TLDs, excluding TLDs from the au, nz and international groups.
function createAllPremiumGroup(){
	var ignoreArr = tldGroups.nz.tlds.concat(tldGroups.international.tlds).concat(tldGroups.generic.tlds);
	var tld;
	var i;
	
	ignoreArr.push("uber.com.au");

	// Note that tld is the tld key as a string, not the object.
	for(tld in tldObj){
		if($.inArray(tld, ignoreArr) == -1){
			tldGroups.allPremium.tlds.push(tld);
		}
	}
	
	tldGroups.allPremium.tlds.sort();
}



function logMessage(str){
	$("#domain_message").removeClass("hidden");
	$("#domain_message").append(str + "<br />\n");
}


/********************************* INIT ALL ROWS *************************************

Show all the domains and pricing when the page loads.

*/

function initDomainPricing(group){
	var cssId = group.cssId;
	var arr = group.tlds;
	var arrMax = arr.length;
	var tld;
	var i;

	for(i = 0; i < arrMax; i++){
		tld = arr[i];
		if(tldObj[tld]){
			addRow(tld, "#" + cssId + " .results");
		}else{
			console.warn("No match found in tldObj for: " + tld);	
		}
	}
}



/********************************* ROW FUNCTIONS *************************************/

function addRow(tld, parentId){
	// Show yearly pricing
	var minRegistration = tldObj[tld].periods[0].years;
	var cost = tldObj[tld].periods[0].registration;
    /*
	if(minRegistration > 1){
		cost = Number((cost / minRegistration).toFixed(2));
	}
    */
    
    var price;
    if(cost){
        if(minRegistration == 1){
            price = "$" + cost + " / yr";
        }else{
            price = "$" + cost + " / " + minRegistration + " yrs";
        }
    }else{
        price = '';
    }
	
	// Create new row
	elem = $("#master .row").clone();
	
	// Rows have their ID set to the TLD with underscores instead of . eg. com.au (tld) -> com_au (css id).
	elem.attr("id", tld.replace(".", "_"));
	
	// Add default information
	$(".domain", elem).html("." + tld);
	$(".price", elem).html(price);
	
	elem.appendTo($(parentId));
}


/**************************** PREMIUM DOMAINS TOGGLE ****************************/

var groupToggle = "premium";
var allPremium = false;
//Animate max-height
var heightMin = 2000;
var heightMax = 30000;

function togglePremiumDomains(){
	if(groupToggle == "premium"){
		groupToggle = "allPremium";	
		$("#premium_toggle i").css("background-position", "top");
		$("#premium_toggle").html("&nbsp; &nbsp; &nbsp;Show Less Domains");
		$("#results_premium .results").css("max-height", heightMax);
	}else{
		groupToggle = "premium";
		$("#premium_toggle i").css("background-position", "bottom");
		$("#premium_toggle").html("&nbsp; &nbsp; &nbsp;Show More Domains");
		$("#results_premium .results").css("max-height", heightMin);
	}
	
	var group = tldGroups[groupToggle];
	
	// Clear old rows
	$("#results_premium .results").children().remove();
	
	// Create new default rows
	initDomainPricing(group);
	
	// Start new domain lookups if required
	if(domainObj){
		domainCheck(domainObj, group);
	}
}


/**************************** SEARCH HISTORY ****************************/

function initSearchHistory(){
	var history = docCookies.getItem("domainSearchHistory");
	$("#search_history .row_clear").addClass("hidden");
	
	if(!history){
		return;
	}
	
	historyArr = history.split(",");
	displaySearchHistory();
}


function updateSearchHistory(domainObj){
	var expires = 604800; // seconds
	var domain = domainObj.domain;
	var i;
	
	// Remove duplicate searches
	var arrMax = historyArr.length;
	
	for(i = 0; i < arrMax; i++){
		if(historyArr[i] == domain){
			historyArr.splice(i, 1);
		}
	}
	
	historyArr.unshift(domain);
	displaySearchHistory();

	// Write cookie
	docCookies.setItem("domainSearchHistory", historyArr.join(","), expires);
}


function displaySearchHistory(){
	var arrMax = historyArr.length;
	var i;
	var str;
	
	$("#search_history .results").children().remove();
	
	// Build the list
	for(i = 0; i < arrMax; i++){
		str = '<a class="word_break" href="javascript:void(0)" onclick="historySearch(\'' + historyArr[i] + '\'); return false;">' + historyArr[i] + '</a>';
		$("#search_history .results").append(str);
	}
	
	$("#search_history .row_clear").removeClass("hidden");
}


function clearSearchHistory(){
	$("#search_history .results").children().remove();
	$("#search_history .row_clear").addClass("hidden");
	
	historyArr = [];
	docCookies.removeItem("domainSearchHistory");
}


function historySearch(str){
	$("#domain").val(str);
	$(".submit").click();	
}



/********************************* COOKIES *************************************

	https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
	
	docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
	docCookies.getItem(name)
	docCookies.removeItem(name[, path[, domain]])
	docCookies.hasItem(name)
	docCookies.keys()
*/

var docCookies = {
  getItem: function (sKey) {
    if (!sKey) { return null; }
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1")) || null;
  },
  
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    var sExpires = "";
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
          break;
        case String:
          sExpires = "; expires=" + vEnd;
          break;
        case Date:
          sExpires = "; expires=" + vEnd.toUTCString();
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + "=" + encodeURIComponent(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + (bSecure ? "; secure" : "");
    return true;
  },
  
  removeItem: function (sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "");
    return true;
  },
  
  hasItem: function (sKey) {
    if (!sKey) { return false; }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  },
  
  keys: function () {
    var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
    for (var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++) { aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]); }
    return aKeys;
  }
};